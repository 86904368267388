import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/template-partials/global-components/section"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"
import aboutBG from "../images/1.0_backgroundheader.jpg"
import legalRound from "../images/legal-round.jpg";

const PrivacyPolicyPage = () => {

  return (
    <Layout>
      <SEO title="Neuroblastoma-Info Privacy Policy" description="" />

      <InteriorPageHeader backgroundImage={aboutBG} roundImage={legalRound} alt="Privacy Policy">
        <h1 className="section__heading section__heading--green h1">Privacy Policy</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page lg_pb-5">
        <div className="row row--inner">
          <div className="columns">
            <div className="row">
              <div className="columns">
              <p><strong>Privacy Policy </strong></p>
              <p>This Privacy Policy describes what information we may collect about you; how we use your information; how we protect it; and what choices you have. Health is a very personal, private subject, and we want you to feel as comfortable as possible visiting our various websites and using their respective services. This Privacy Policy applies only to websites that directly link to this policy when you click on "privacy policy" in the website footer.</p>
              <p><strong>Personally Identifiable Information </strong></p>
              <p>United Therapeutics Corporation ("UT") may collect personally identifiable information such as name, address, telephone number, e-mail address, or other information only when voluntarily submitted by the visitor (i.e. on a web form you fill out and submit). UT uses or discloses personally identifiable information only with the visitor's consent. UT will comply with any limitations on use communicated when personally identifiable information is collected.</p>
              <p>UT will not sell, trade, or rent your personally identifiable information to others. UT may however, share your personally identifiable information with other UT businesses or affiliates, our agents, contractors or business partners in order that they may perform services for us.</p>
              <p>UT may use your personally identifiable information for the purposes of sending you information about this website, marketing communications about its products, information about disease states treated with UT's products, information about support groups for patients and care givers, educational opportunities, conferences, and seminars, an similar information.</p>
              <p><strong>Non-Personally Identifiable Information </strong></p>
              <p>UT collects non-personally identifiable information in aggregate form to track data such as the total number of visits to our websites, the number of visitors to each page of our websites, and the domain names of our visitors' Internet service providers. We use this information, which remains in aggregate form, to understand how our visitors use our websites so that we may make them better. We may also share this information with our partners or other outside companies. This information is gathered primarily through the use of "cookies." You may be able to change the manner in which your browser manages cookies through your browser's privacy settings. NO PERSONALLY IDENTIFIABLE INFORMATION IS OBTAINED IN THIS PROCESS.</p>
              <p>UT uses third party advertising companies to advertise on our behalf across the Internet. These third parties may collect anonymous information about your visits to our website, and your interaction with our ads. This is primarily accomplished through technologies including cookies and Action Tags (commonly referred to as a Web Beacons or GIF tags), which are placed on various places within our website. NO PERSONALLY IDENTIFIABLE INFORMATION IS DISCLOSED IN THIS PROCESS.
              </p>
              <p><strong>Security</strong></p>
              <p>UT uses technical and organizational security precautions to protect your data from manipulation, loss, destruction or access by unauthorized persons. Any personal data that is provided to UT by you will be secured in transit to prevent its possible misuse by third parties. Our security procedures are revised from time to time based on new technological developments.</p>
              <p><strong>Voluntary Disclosures </strong></p>
              <p>Any communications or material that you transmit to, or post on, the site by electronic mail or otherwise, including any data, questions, comments, suggestions, or the like, is, and will be treated as, non-confidential and non-proprietary information, and UT shall have no obligation of any kind with respect to such information except as otherwise stated in this Privacy Policy.</p>
              <p><strong>Links to Other websites </strong></p>
              <p>UT websites may provide links to other websites as a service to our viewers. These linked websites are intended to provide helpful information. However, UT does not endorse and is not responsible for the content of third-party sites. Nor does UT have any control over the information you may choose to provide these sites. This Privacy Policy does not apply to linked sites.</p>
              <p><strong>Changes to Privacy Policy </strong></p>
              <p>Any changes to this Privacy Policy will be communicated promptly on this page. Please check back periodically.</p>
              </div>
            </div>
          </div>
        </div>
      </Section>

    </Layout>
  )
}

export default PrivacyPolicyPage
